import React from 'react';

import "./css/RevealModal.css";
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { Shake } from 'reshake';

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class LotteryLoadingModal extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    box: undefined,
	    showRevealLoading: false
        };
    }    

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }
    
    open(box) {
	this.setState({
	    box: box,
	    showRevealLoading: true
	});
    }
    
    close() {
        this.setState({ showRevealLoading: false });
    }

    render() {
	const {
	    box,
            showRevealLoading,
	} = this.state;
	return (
            <Button
		className="hidden-button"
		onClick={this.close.bind(this)}>
		<Modal
		    isOpen={showRevealLoading}
		    ariaHideApp={false}
		    style={modalStyles}
		    contentLabel="Example Modal">
		    <div className="modal-wallets modal-reveal">
			<div className="modal-content">
			    <h3>Box open </h3>
			    <p>
				Even if you cancel now,<br/>
				you can check it on the website later.<br/>
				(지금 취소하셔도 이후에 홈페이지에서<br/>
				확인하실 수 있습니다)
			    </p>
			    <Shake 
				h={5}
				v={5}
				r={3}
				dur={300}
				int={10}
				max={100}
				fixed={true}
				fixedStop={false}
				freez={false}>
				{ box?
				  <img className="box" src={box.image_url} alt=""/>
				  :""
				}
			    </Shake>
			</div>
		    </div>
		</Modal>              
            </Button>
	);
    }
}


export default LotteryLoadingModal;
