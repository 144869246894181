import React from 'react';

import "./css/PlanSlidingMenu.css";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class PlanSlidingMenu extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    title: props.title,
            open: props.initialOpenState,
	    plan: props.plan,
	    type: props.type,
	    nftList: props.nftList,
	    selectedItems: [],
        };
	this.locale = navigator.language === "ko-KR" ? "kr" : "en";
    }

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }

    handleOpen(e) {
	e.stopPropagation();
	this.setState({
	    open: !this.state.open
	});
    }

    getContentView(type, plan, nftList) {
	if (type === "explain") {
	    return this.getExplainContentView(plan);
	} else if (type === "select") {
	    return this.getSelectContentView(plan, nftList);
	}
	return (<div></div>);
    }

    getExplainContentView(plan) {
        let views = this.props.content.split("\n").map(
            (line, index) => (
                <li key={index}>
                  {line}
                </li>
            )
        );
	return (
	    <div className="how-it-works scroll-container">
              <ol>
                {views}
              </ol>
	    </div>
	);	
    }

    getSelectContentView(plan, nftList) {
	let {
	    itemRows, itemCount
	} = this.prepareItemRows(nftList);
	return (
	    <div className="gallery-list scroll-container">
	 	{itemCount > 0?
		 itemRows:
		 ""
		}
		{itemCount === 0?
		 <div>No results</div>:
		 ""
		}
	    </div>
	);
    }

    handleItemClick(box, e) {
	e.stopPropagation();
	if (this.state.selectedItems.includes(box)) {
	    const idx = this.state.selectedItems.indexOf(box);
	    if (idx > -1) this.state.selectedItems.splice(idx, 1);
	} else {
	    this.state.selectedItems.push(box);
	}
	this.setState({
	    selectedItems: this.state.selectedItems
	});
    }

    render() {
	const {
	    title,
            open,
	    plan,
	    type,
	    nftList
	} = this.state;
	
	let contentView = this.getContentView(
	    type,
	    plan,
	    nftList
	);
	
	return (
	    <div className="plan-sliding-menu">
		<div className="sliding-title">
		    {title}
		    <a href="#none"
		       onClick={this.handleOpen.bind(this)}>
			<i className={"arrow " + (open?'up':'down')}></i>
		    </a>
		</div>
		<div className={"sliding-content " + (open?'open':"close")}>
		    {contentView}
		</div>
	    </div>
	);
    }

    prepareItemRows(nftList) {
	let rows = [];
	let colCount = 0;
	let cols = [];
	let index = 0;
	let rowIndex=0;
	let selectedList = nftList;
	if (!selectedList) {
	    return {
		itemRows: rows,
		itemCount: index,
	    };
	}
	for (let box of selectedList) {
	    index++;
	    cols.push(
		(
		    <Col md={4} key={index}>
			<a
			    className="gallery-item"
			    href="#none"
			    onClick={this.handleItemClick.bind(this, box)}>
			    <img src={box.image_url} alt=""/>
			    {box.generation !== undefined?
			     <div className="generation">
				 G{box.generation}
			     </div>:""}
			    <div className="item-id">
				#{String(box.number).padStart(6, '0')}
			    </div>
			    {this.state.selectedItems.includes(box)?
			     <div className="selected-cover">
				 <img src="/ic_check.png" alt=""/>
			     </div>
			     :<div></div>
			    }
			</a>
		    </Col>
		)
	    );
	    colCount++;
	    if (colCount === 3) {
		rowIndex++;
		rows.push(
		    (
			<Row key={rowIndex}>
			    {cols}
			</Row>
		    )
		);
		cols = [];
		colCount = 0;
	    }
	}
	rowIndex++;
	if (colCount > 0) {
	    rows.push(
	        (
		    <Row key={rowIndex}>{cols}</Row>
	        )
	    );
	}
	return {
	    itemRows: rows,
	    itemCount: index,
	};
    }

    getSelectedItems() {
	return this.state.selectedItems;
    }
    
}

export default PlanSlidingMenu;
