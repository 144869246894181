import React from 'react';

import "./css/SelectContent.css";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import ApiService from '../api/api.js';
import sleep from '../utils/sleep.js';

class SelectContent extends React.Component {

    constructor(props) {
	super(props);
	this.apiService = new ApiService(props.navigate);
	this.actionList = [
	    {
		type: "rewards",
		kr: "랜드 & 슈박스 플랜 보상",
		en: "LAND & SHOEBOX PLAN REWARDS",
		nftType: "shoes",
		enable: true,
		new: true,
	    },
	    {
		type: "new",
		kr: "슈박스 플랜 가입하기",
		en: "ACTIVATE NEW SHOEBOX PLAN",
		nftType: "shoes",
		enable: true,
	    },
	    {
		type: "shoebox-plan-upgrade",
		kr: "슈박스 플랜 업그레이드",
		en: "SHOEBOX PLAN UPGRADE",
		nftType: "shoes",
		enable: true,
		new: true,
	    },

	    {
		type: "fit",
		kr: "FIT 플랜",
		en: "FIT PLAN",
		nftType: "shoes",
		enable: true,
	    },
	    {
		type: "fit-plan-upgrade",
		kr: "FIT 플랜 업그레이드",
		en: "FIT PLAN UPGRADE",
		nftType: "shoes",
		enable: true,
		new: true,
	    },
	    // {
	    // 	type: "openbox-collect",
	    // 	kr: "오픈박스 수집 리워드",
	    // 	en: "OPENBOX COLLECT REWARD",
	    // 	nftType: "openbox",
	    // 	enable: true,
	    // },
	    // {
	    // 	type: "openbox-collection",
	    // 	kr: "오픈박스 컬렉션 리워드",
	    // 	en: "OPENBOX COLLECTION REWARD",
	    // 	nftType: "openbox",
	    // 	enable: true,
	    // },
	    {
		type: "global_land",
		kr: "글로벌 랜드 에어드랍",
		en: "GLOBAL LAND AIRDROP",
		nftType: "shoes",
		enable: true,
	    },
	    // {
	    // 	type: "global-land-issue",
	    // 	kr: "글로벌 랜드 플랜 업그레이드 접수",
	    // 	en: "GLOBAL LAND PLAN",
	    // 	enable: true,
	    // 	new: true
	    // },
	    // {
	    // 	type: "reveal-lottery",
	    // 	kr: "로터리 리빌",
	    // 	en: "LOTTERY REVEAL",
	    // 	nftType: "lottery",
	    // 	enable: true,
	    // },
	];
	this.state = {
	    selectedAction: this.actionList[0],
	};
    }

    componentDidMount() {
	this.checkLogin();
    }

    async checkLogin() {
	await sleep(100);
	if (!this.apiService.isLoggedIn()) {
	    this.props.navigate("/");
	}
    }

    handleSelectClick(action) {
	this.setState({
	    selectedAction: action
	});
    }

    handleNextClick() {
	this.props.onSelectAction(
	    this.state.selectedAction
	);
    }

    render() {
	const views = this.actionList.map((action) =>
	    (
		<a href="#none"
		   className={"action-line " + (action.type === this.state.selectedAction.type ? 'selected' : '')}
		   onClick={this.handleSelectClick.bind(this, action)}
		   key={action.type}>
		    <div className="card">
			<span className={"radio " + (action.type === this.state.selectedAction.type ? 'ic-radio-selected' : 'ic-radio-unselected')}></span>
			<h1>{action.kr}</h1>
			<p>{action.en}</p>
			{!action.enable?<p>점검중 (Under Inspection)</p>:""}
		        {!action.enable?<p>{action.time}</p>:""}
                        {action.new?<span className="new-tag">NEW</span>:""}
		    </div>
		</a>
	    )
	);
        return (
	    <div className="container-root">
		<Container fluid="md">
		    <div className="container-reveal container-actions">
			<img className="title-img" src="main-title.png" alt=""/>
			{ views }
			{this.state.selectedAction?
			 <Button disabled={!this.state.selectedAction.enable} className="snkrz-btn-normal dark" onClick={this.handleNextClick.bind(this)}>
			    NEXT
			 </Button>
			 :""
			}
		    </div>
		</Container>
	    </div>
	);
    }
}


export default SelectContent;
