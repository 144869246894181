import React from 'react';

import "./css/RevealContent.css";

import LotteryGallery from './LotteryGallery.js';
import LotteryLoadingModal from './LotteryLoadingModal.js';
import LotteryResultModal from './LotteryResultModal.js';

import Container from 'react-bootstrap/Container';

import ApiService from '../api/api.js';
import sleep from '../utils/sleep.js';

class LotteryContent extends React.Component {
    
    constructor(props) {
	super(props);
	this.apiService = new ApiService(this.props.navigate);
	this.state = {
	    action: props.action,
            nftList: {
                box: [],
                item: [],
            },	    
	};
	
	this.revealLoadingRef = React.createRef();
	this.revealResultRef = React.createRef();
    }

    componentDidMount() {
	this.loadNFTList();	
    }

    async loadNFTList() {
	let type = "";
	if (this.props.action) type = this.props.action.type
	if (type === "reveal-lottery") {
	    let response = await this.apiService.getLotteryList(this.props.action.nftType);
	    if (response.status === 200) {
		this.setState({
		    nftList: {
			box: response.data.box,
			item: response.data.item
		    }
		});
	    }
	} else {
	    sleep(100);
	    this.props.navigate("/");
	}
    }

    async handleBoxOpenClick(box) {
	this.revealLoadingRef.current.open(box);
	let response = await this.apiService.revealLottery(
	    box.id
	);
	await sleep(500);
	this.revealLoadingRef.current.close();
	if (response.status === 200) {
	    this.revealResultRef.current.open(response.data);
	    this.loadNFTList();
	} else {
	    alert("ERROR")
	}	
    }
    
    render() {
	const {
            nftList,
	} = this.state;
	let title = "";
	let desc = "";
	if (this.state.action) {
	    title = this.state.action.kr;
	    desc = this.state.action.en;
	}
        return (
	    <div>
		<div className="container-root"> 
		    <Container fluid="md">
			<div className="container-reveal container-actions">
			    <h1>{title}</h1>
			    <p>{desc}</p>
			    <LotteryGallery
				nftList={nftList}
				handleBoxOpenClick={this.handleBoxOpenClick.bind(this)}/>
			</div>
		    </Container>
		</div>
		<LotteryLoadingModal
		    ref={this.revealLoadingRef}/>
		<LotteryResultModal
		    ref={this.revealResultRef}/>
	    </div>
	);
    }
    
}


export default LotteryContent;
