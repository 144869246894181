import React from 'react';

import "./css/RevealModal.css";
import "./css/PlanUpgradeModal.css";
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import ApiService from '../api/api.js';

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class PlanDeactivateModal extends React.Component {

    constructor(props) {
	super(props);
	this.state = {
            open: props.open,
	    plan: undefined,
	    nftList: props.nftList,
	    feeInfo: undefined,
        };
	this.locale = navigator.language === "ko-KR" ? "kr" : "en";
	this.selectorRef = React.createRef();
	this.apiService = new ApiService(props.navigate);
    }

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }

    open(plan) {
	this.setState({
	    open: true,
	    plan: plan
	});
	this.loadFeeInfo(plan);
    }

    async loadFeeInfo(plan) {
	let response = await this.apiService.getDeactivatePlanFee(this.props.action.type, plan.plan_key);
	if (response.status === 200) {
	    this.setState({
		feeInfo: response.data
	    });
	} else {
	    this.close();
	}
    }

    async deactivatePlan(plan) {

	let response = await this.apiService.deactivatePlan(this.props.action.type, plan.plan_key);
	if (response.status === 200) {
	    alert("DEACTIVATE SUCCESS");
	    this.close();
	    this.props.onDeactivateConfirmed(plan);
	}
    }

    close() {
        this.setState({open: false});
    }

    confirm(e) {
	e.stopPropagation();
	this.deactivatePlan(this.state.plan);
    }

    getFeeList(feeInfo) {
	let ret = {}
	if (!feeInfo) return ret;
	for (let feeString of feeInfo.fee.split("+")) {
	    feeString = feeString.trim();
	    if (feeString.includes("FRC")) {
		ret["frc"] = feeString.split(" ")[0];
	    }
	    if (feeString.includes("FIT")) {
		ret["fit"] = feeString.split(" ")[0];
	    }
	}
	return ret;
    }

    render() {
	const {
            open,
	    plan,
	    feeInfo,
	} = this.state;
	let frcBalanceString = "-";
        let fitBalanceString = "-";
	if (feeInfo) {
	    if (feeInfo.balance_frc > 0) {
		frcBalanceString = Math.floor(feeInfo.balance_frc * 1000)/1000 + " FRC";
	    }
            if (feeInfo.balance_fit > 0)  {
		fitBalanceString = Math.floor(feeInfo.balance_fit * 1000)/1000 + " FIT";
	    }
	}
	let feeList = this.getFeeList(feeInfo);
	return (
            <Button
		className="hidden-button"
		onClick={this.close.bind(this)}>
		<Modal
		    isOpen={open}
		    ariaHideApp={false}
		    style={modalStyles}
		    contentLabel="Example Modal">
		    <div className="modal-wallets modal-content modal-login modal-upgrade">
			<div className="modal-header">
			    <h3>{plan?plan.name:""}</h3>
			    <Button className="close-button" onClick={this.close.bind(this)}>X</Button>
			</div>
			<div className="modal-container">
			    <div className="plan-desc-container">
				<ul>
				    <li>
					<div className="plan-desc-title">
					    Fee
					</div>
					<div className="plan-desc-content">
					    {feeInfo?feeInfo.fee:""}
					</div>
				    </li>
				    <li>
					<div className="plan-desc-title">
					    FRC Balance
					</div>
					<div className={`plan-desc-content ${feeInfo && feeList.frc > feeInfo.balance_frc?"red":""}`}>
					    {frcBalanceString}
					</div>
				    </li>
				    <li>
					<div className="plan-desc-title">
					    FIT Balance
					</div>
					<div className={`plan-desc-content ${feeInfo && feeList.fit > feeInfo.balance_fit?"red":""}`}>
					    {fitBalanceString}
					</div>
				    </li>
				</ul>
			    </div>
			    <Button className="ok-button deactivate" onClick={this.confirm.bind(this)} disabled={feeInfo?!feeInfo.can_deactivate:true}>DEACTIVATE</Button>
			</div>
		    </div>
		</Modal>
            </Button>
	);
    }
}


export default PlanDeactivateModal;
