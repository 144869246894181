const axios = require('axios');

const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;

export default class ApiService {

    constructor(navigate) {
	this.accessToken
	    = sessionStorage.getItem('x-web-access-token');
	this.navigate = navigate;
    }

    async get(path) {
	console.log("GET", path);
	try {
	    let response = await axios.get(DOMAIN_URL + path, {
		headers: {
		    'x-web-access-token': this.accessToken
		}
	    });
            console.log(response);
	    return response;
	} catch(e) {
	    // console.log(e);
            this.handleError(path, e);
	    return e.response;
	}
    }

    async post(path, body) {
	console.log("POST", path);
        console.log(body);
	try {
	    let response = await axios.post(
		DOMAIN_URL + path,
		body,
		{
		    headers: {
			'x-web-access-token': this.accessToken
		    }
		}
	    );
	    console.log(response);
	    return response;
	} catch(e) {
	    // console.log(e);
            this.handleError(path, e);
	    return e.response;
	}
    }

    handleError(path, e){
        console.log(e);
	if (e.response.status === 404) {
	    if (path === "users/get-verification/") {
		alert("Not a SNKRZ user");
		sessionStorage.setItem(
		    'email',
		    undefined
		);
		sessionStorage.setItem(
		    'x-web-access-token',
		    undefined
		);
		if (this.navigate) this.navigate("/");
	    } else {
		alert("Session Expired. Please logout and login again");
		if (this.navigate) this.navigate("/");
	    }
	} else {
            if (e.response.data && e.response.data.error) {
                let error = e.response.data.error;
                alert(error.title + "\n" + error.message);
            } else {
                alert("Error");
            }
        }
    }

    // authorization
    async getVerification(email) {
	return await this.post(
	    "users/get-verification/",
	    {
		email: email
	    }
	);
    }

    async verifyEmail(email, code) {
	return await this.post(
	    "users/verify-email/",
	    {
		email: email,
		code: code
	    }
	);
    }

    isLoggedIn() {
	let result = this.accessToken !== undefined && this.accessToken !== "undefined" && this.accessToken !== null && this.accessToken.length > 10;
	// console.log(this.accessToken, result);
	return result;
    }

    getBaseUrl(actionType) {
	let baseUrl = "";
	let responseType = "";
	if (["openbox-collection", "openbox-collect", "seoul-land"].includes(actionType)) {
	    baseUrl = "achievements"
	    responseType = "achievement";
	} else {
	    baseUrl = "plans"
	    responseType = "plan";
	}
	return {
	    baseUrl: baseUrl,
	    responseType: responseType
	};
    }

    // claim
    async getAvailableAchievements(actionType) {
	let baseUrlInfo = this.getBaseUrl(actionType);
	let url = baseUrlInfo.baseUrl + "/" + actionType + "/";
	if (actionType === "shoebox-plan-upgrade") {
	    url = "plans/new/upgrade/";
	} else if (actionType === "fit-plan-upgrade") {
	    url = "achievements/plans/fnf/upgrade/";
	}
	let response = await this.get(url);
	if (actionType === "fit" && !response.data.myPlan) {
	    response = await this.get(
		"achievements/plans/fnf/"
	    )
	    response.data["myPlan"] = {
		"name": "SPECIAL BENEFIT",
		"plan_key": "",
		"is_active": true,
		"type": "auto",
		"deactivate_enabled": true,
		"can_deactivate": null,
		"rewards": []
	    };
	    for (let plan of response.data["planList"]) {
		plan.is_achievement = true;
	    }
	    for (let reward of response.data["rewardList"]) {
		reward.is_achievement = true;
	    }
	}
	response["type"] = baseUrlInfo.responseType;
	return response;
    }

    async claimPlanReward(actionType, planKey, claimKey, planGroup) {
	if (actionType === "rewards") {
	    return await this.post(
		"plans/rewards/claim/",
		{
		    plan_group: planGroup,
		    claim_key: claimKey,
		}
	    );
	}
	return await this.post(
	    "plans/" + actionType + "/" + planKey + "/claim/" + claimKey + "/",
	    {}
	);
    }

    async claimReward(actionType, rewardKey, isAchievement) {
	let baseUrlInfo = this.getBaseUrl(actionType);
	if (isAchievement) {
	    return await this.post(
		"achievements/" + actionType + "/claim/" + rewardKey + "/",
		{}
	    );
	}
	return await this.post(
	    baseUrlInfo.baseUrl + "/" + actionType + "/claim/" + rewardKey + "/",
	    {}
	);
    }

    async registerPlan(actionType, planKey, body, isAchievement) {
	if (isAchievement) {
	    if (planKey.includes("fnf")) {
		return await this.post(
		    "achievements/plans/fnf/",
		    {}
		)
	    }
	    return await this.post(
		"achievements/plans/" + planKey + "/",
		{}
	    )
	}
	return await this.post(
	    "plans/" + actionType + "/register/" + planKey + "/",
	    body
	);
    }


    async getRevealList(nftType) {
	return await this.get(nftType + "/");
    }

    async revealBox(boxId) {
	return await this.post(
	    "skin/reveal/" + boxId + "/",
	    {}
	);
    }

    async getLotteryList() {
	return await this.get("lottery/");
    }

    async revealLottery(lotteryId) {
	return await this.post(
	    "lottery/reveal/" + lotteryId + "/",
	    {}
	);
    }

    async getDeactivatePlanFee(action, planKey) {
	return await this.get(
	    "plans/" + action + "/" + planKey + "/deactivate/"
	);
    }

    async deactivatePlan(action, planKey) {
	return await this.post(
	    "plans/" + action + "/" + planKey + "/deactivate/"
	);
    }

    async upgradePlan(action, planKey, toPlanKey, body){
	if (action === "shoebox-plan-upgrade") {
	    return await this.post(
		"plans/new/upgrade/" + toPlanKey + "/",
		body
	    );
	}
	if (action === "fit-plan-upgrade") {
	    return await this.post(
		"achievements/plans/fnf/upgrade/" + toPlanKey + "/",
		body
	    );
	}
	return await this.post(
	    "plans/" + action + "/" + planKey + "/upgrade/" + toPlanKey + "/",
            body
	);
    }
}
