import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import {MainPageWithRouter} from './pages/MainPage.js';

function App() {
    return (
	<div>
	  <div className="App">
            <MainPageWithRouter />
	  </div>
	</div>
    );
}


export default App;
