import React from 'react';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import ApiService from '../api/api.js';

import sleep from '../utils/sleep.js';

class DefaultContent extends React.Component {

    constructor(props) {
	super(props);
	this.apiService = new ApiService(props.navigate);
    }
    
    componentDidMount() {
	this.checkLogin();
    }

    async checkLogin() {
	await sleep(100);
	if (this.apiService.isLoggedIn()) {
	    this.props.navigate("/select");
	}
    }
    
    render() {
	return (
            <div className="container-root">
		<div className="intro-banner">
                    <Container fluid="md">
			<img className="title-img" src="main-title.png" alt=""/>
			<p>스니커즈 크루를 위한 스페셜 혜택 공간</p>
			<Button
			    variant="light"
			    className="btn-login"
			    onClick={this.props.handleWalletConnect}>
			    LOGIN
			</Button>
                    </Container>
		</div>
            </div>
	);
    }
}


export default DefaultContent;
