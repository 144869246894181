import React from 'react';

import "./css/RevealModal.css";

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class LotteryResultModal extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
            item: undefined,
	    showRevealResult: false
        };
    }    

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }

    open(item) {
	this.setState({
	    item: item,
	    showRevealResult: true
	});
    }
    
    close() {
        this.setState({ showRevealResult: false });
    }
    
    render() {
	const {
            showRevealResult,
            item,
	} = this.state;
	return (
            <Button
		className="hidden-button"
		onClick={this.close.bind(this)}>
		<Modal
		    isOpen={showRevealResult}
		    ariaHideApp={false}
		    style={modalStyles}
		    contentLabel="Example Modal">
		    <div className="modal-wallets modal-reveal-result">
			{ item?
			<div className="modal-content">
			    <img className="box" src={item.image_url} alt=""/>
			    <h1>{item.title}</h1>
			    <p>{item.desc}</p>
			    <Button className="ok-button" onClick={this.close.bind(this)}>OK</Button>
			</div>
			  :""
			}
		    </div>
		</Modal>              
            </Button>
	);
    }
}


export default LotteryResultModal;
