import React from 'react';

import "./css/RevealModal.css";
import "./css/PlanUpgradeModal.css";
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import langBox from "./lang/PlanUpgradeModal.json";
import langFit from "./lang/PlanUpgradeModalFit.json";

import PlanSlidingMenu from './PlanSlidingMenu.js';
import ApiService from '../api/api.js';

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class PlanUpgradeModal extends React.Component {

    constructor(props) {
	super(props);
	this.state = {
            open: props.open,
	    plan: undefined,
	    nftList: props.nftList,
            action: props.action,
            basePlan: props.basePlan,
        };
	this.locale = navigator.language === "ko-KR" ? "kr" : "en";
	this.selectorRef = React.createRef();

        this.apiService = new ApiService(props.navigate);
	this.lang = langBox;
	if (props.action &&
	    props.action.type === "fit-plan-upgrade") {
	    this.lang = langFit;
	}
    }

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }

    open(plan) {
	this.setState({
	    open: true,
	    plan: plan
	});
        console.log(plan);
    }

    close() {
        this.setState({open: false});
    }

    confirm(e) {
	// this.props.onPlanConfirmed(this.state.plan);
	e.stopPropagation();
        let selectedItems = [];
        if (this.selectorRef.current) {
	    selectedItems = this.selectorRef.current.getSelectedItems();
        }
        if (this.state.basePlan) {
            this.upgradePlan(selectedItems.map(x => x.id));
        } else {
            this.registerPlan(selectedItems.map(x => x.id));
        }
    }

    async registerPlan(shoesIds) {
        let response = await this.apiService.registerPlan(
            this.state.action.type,
            this.state.plan.planKey,
            {
                shoes_ids: shoesIds
            },
            this.state.plan.is_achievement
        );
        if (response.status === 200) {
            this.close();
            alert("REGISTER SUCCESS");
            this.props.onPlanConfirmed(this.state.plan);
        }
    }

    async upgradePlan(shoesIds) {
	let planKey = this.state.plan.plan_key;
	if (!planKey) planKey = this.state.plan.planKey;
        let response = await this.apiService.upgradePlan(
            this.state.action.type,
            this.state.basePlan.plan_key,
            planKey,
            {
                shoes_ids: shoesIds
            }
        );
        if (response.status === 200) {
            this.close();
            alert("UPGRADE SUCCESS");
            this.props.onPlanConfirmed(this.state.plan);
        }
    }

    render() {
	const {
            open,
	    plan,
	    nftList,
	} = this.state;
	let rewardView = (<div></div>);
        let additionalRewards = undefined;
        if (plan && plan.additional_rewards) {
            if (Array.isArray(plan.additional_rewards)) {
                plan.rewards = plan.additional_rewards;
            } else {
                additionalRewards = plan.additional_rewards;
            }
        }
	if (plan && plan.rewards) {
	    rewardView = plan.rewards.map(
		(reward, index) => (
		    <li key={index}>
		      <div className="plan-desc-title">
			{reward.description}
		      </div>
		      <div className="plan-desc-content">
			{reward.title}
		      </div>
		    </li>
		)
	    );
	}
	return (
            <Button
	      className="hidden-button"
	      onClick={this.close.bind(this)}>
	      <Modal
		isOpen={open}
		ariaHideApp={false}
		style={modalStyles}
		contentLabel="Example Modal">
		<div className="modal-wallets modal-content modal-login modal-upgrade">
		  <div className="modal-header">
		    <h3>{plan && plan.name?plan.name:""}{plan && plan.title?plan.title:""}</h3>
		    <p>
		      {plan?plan.description:""}
		    </p>
		    <Button className="close-button" onClick={this.close.bind(this)}>X</Button>
		  </div>
		  <div className="modal-container">
                    {plan && plan.minimum_required_snkrz?
		     <div className="plan-desc-container">
		       <ul>
                         {additionalRewards?
			  <li>
			    <div className="plan-desc-title">
			      {this.lang.additionalRewards[this.locale]}
			    </div>
			    <div className="plan-desc-content">
			      {plan?plan.additional_rewards:""}
			    </div>
		          </li>:""}
			 <li>
			   <div className="plan-desc-title">
			     {this.lang.minimumRequiredSNKRZ[this.locale]}

			   </div>
			   <div className="plan-desc-content">
			     {plan?plan.minimum_required_snkrz:""}
			   </div>
			 </li>
			 <li>
			   <div className="plan-desc-title">
			     {this.lang.additionalPlanDuration[this.locale]}
			   </div>
			   <div className="plan-desc-content">
			     {plan?plan.additional_plan_duration:""}
			   </div>
			 </li>
		       </ul>
		     </div>
                     :""}
                    {plan && plan.minimum_required_snkrz?
		     <div className="plan-generation-guide">
		       {this.lang.generationGuide[this.locale]}
		     </div>
                     :""}
                    {plan && plan.rewards && plan.rewards.length !== 0?
                     <div>
		       <div>REWARD</div>
		       <div className="plan-desc-container">
			 <ul>
			   {rewardView}
			 </ul>
		       </div>
                     </div>:""}
                    {plan && plan.type === "select"?
		     <PlanSlidingMenu
		       ref={this.selectorRef}
		       title="MY SNKRZ"
		       plan={plan}
		       initialOpenState={true}
		       type="select"
		       nftList={nftList}/>:""}
		    <PlanSlidingMenu
		      title="HOW IT WORKS"
		      plan={plan}
		      initialOpenState={plan && plan.type === "auto"}
		      type="explain"
		      content={this.state.basePlan?this.lang.howItWorksUpgrade[this.locale]:this.lang.howItWorksRegister[this.locale]}/>
		    <Button className="ok-button" onClick={this.confirm.bind(this)}>CONFIRM</Button>
		  </div>
		</div>
	      </Modal>
            </Button>
	);
    }
}


export default PlanUpgradeModal;
