import React from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import "./css/HeaderView.css";
import LoginButton from './LoginButton';

class HeaderView extends React.Component {
    
    constructor(props) {
	super(props);
	this.walletSelectorRef = React.createRef();
    }
    
    connectWallet() {
	this.walletSelectorRef.current.onConnectButtonClick();
    }
    
    render() {
	return (
	    <Navbar className="navbar">
		<Container fluid="md">
		    <Navbar.Brand href="/" className="logo"></Navbar.Brand>
		    <Navbar.Toggle />
		    <Navbar.Collapse className="justify-content-end">
			<LoginButton
			    ref={this.walletSelectorRef}
			    onLogin={this.props.onLogin}
			    onLogout={this.props.onLogout}
			    navigate={this.props.navigate}/>
		    </Navbar.Collapse>
		</Container>
	    </Navbar>	    
	);
    }
}


export default HeaderView
