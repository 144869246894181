import React, { Component } from 'react';

import "./css/MainPage.css";

import { useNavigate, Routes, Route } from "react-router-dom";

import HeaderView from './HeaderView.js';
import Footer from './Footer.js'
import DefaultContent from './DefaultContent.js'
import SelectContent from './SelectContent.js'
import RevealContent from './RevealContent.js'
import LotteryContent from './LotteryContent.js'
import AchievementContent from './AchievementContent.js'


class MainPage extends Component {

    constructor(props) {
	super(props);
	this.state = {
	    action: undefined,
	};
	this.headerViewRef = React.createRef();
    }

    onLogin() {
	this.props.navigate("/select")
    }

    onLogout() {
	this.props.navigate("/")
    }

    onSelectAction(action) {
	this.setState({ action: action })
	if (!action) {
	    return
	} else if (action.type === "reveal-skin") {
	    this.props.navigate("/reveal");
	} else if (action.type === "reveal-lottery") {
	    this.props.navigate("/lottery");
	// } else if (action.type === "fit-plan-upgrade") {
	//     window.open("https://bit.ly/FIT_Plan_Upgrade");
	// } else if (action.type === "shoebox-plan-upgrade") {
	//     window.open("https://bit.ly/Shoebox_Plan_Upgrade");
	} else {
	    this.props.navigate("/achievement");
	}
    }

    handleWalletConnect() {
	this.headerViewRef.current.connectWallet();
    }

    render() {
	return (
	    <div>
		<HeaderView
		    ref={this.headerViewRef}
                    onLogin={this.onLogin.bind(this)}
		    onLogout={this.onLogout.bind(this)}
		    navigate={this.props.navigate}/>
		<Routes>
		    <Route
			path="/"
			element={
			    <DefaultContent
				navigate={this.props.navigate}
				handleWalletConnect={this.handleWalletConnect.bind(this)}/>
			}/>
		    <Route
			path="/select"
			element={
			    <SelectContent
				onSelectAction={this.onSelectAction.bind(this)}
				navigate={this.props.navigate}/>
			}/>
		    <Route
			path="/reveal"
			element={
			    <RevealContent
				action={this.state.action}
				navigate={this.props.navigate}/>
			}/>
		    <Route
			path="/lottery"
			element={
			    <LotteryContent
				action={this.state.action}
				navigate={this.props.navigate}/>
			}/>
		    <Route
			path="/achievement"
			element={
			    <AchievementContent
				action={this.state.action}
				navigate={this.props.navigate}/>
			}/>
		</Routes>
		<Footer/>
	    </div>
	);
    }

};

export function MainPageWithRouter(props) {
    const navigate = useNavigate();
    return (<MainPage navigate={navigate}></MainPage>);
}

export default MainPage;
