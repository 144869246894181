import React from 'react';

import "./css/ItemGallery.css";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

import ApiService from '../api/api.js';

class AchievementGallery extends React.Component {

    constructor(props) {
	super(props);
	this.apiService = new ApiService(props.navigate);

	this.state = {
	    action: props.action,
	    nftType: props.nftType,
            nftList: props.nftList,
	    planList: props.planList,
            rewardList: props.rewardList,
	    myPlan: props.myPlan,
	    myplans: props.myplans,
	    upgradePlans: props.upgradePlans,
	    selectedItem: undefined,
	    selectedPlan: undefined,
        };
    }

    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    let selectedPlan = this.state.selectedPlan;
            if (this.props.planList) {
	        for (let plan of this.props.planList) {
		    if (plan.registered) {
		        selectedPlan = plan;
		        break;
		    }
		    if (plan.enabled) {
		        if (!selectedPlan)
			    selectedPlan = plan;
		    }
	        }
            }
	    this.setState(this.props);
	    this.setState({
		selectedPlan: selectedPlan
	    });
	}
    }

    handleItemClick(box) {

    }

    preparePlanAndRewards(myPlan, planList, selectedPlan) {

        let selectedPlanFinal = selectedPlan;

	let planRegistered = false;
	let planEnabled = false;
	if (planList) {
	    for (let plan of planList) {
		if (plan.registered) {
		    selectedPlanFinal = plan;
		    planRegistered = true;
		}
		if (plan.enabled) {
		    planEnabled = true;
		}
	    }
	}

	let selectedPlanKey = "";
        if (selectedPlanFinal) selectedPlanKey = selectedPlanFinal.planKey;

	// plan list
        let planViews = (<div></div>);
	let planCount = 0;
        if (planList) {
	    planCount = planList.length;
	    planViews = planList.map(
                (plan, index) =>
	            (
		        <a href="#none"
		           className={"action-line " + (plan.planKey === selectedPlanKey ? 'selected' : '') + " " + (plan.enabled ? 'enabled' : 'disabled')}
		           onClick={this.handleSelectPlanClick.bind(this, plan)}
		           key={"plan"+index}>
		          <div className="card">
	 		    <span className={"radio " + (plan.planKey === selectedPlanKey ? 'ic-radio-selected' : 'ic-radio-unselected')}></span>
	 		    <h1>{plan.name}</h1>
	 		    <p>{plan.description}</p>
	 	          </div>
		        </a>
	            )
	    );
        }
        let rewardViews = (<div></div>);
	let rewardCount = 0;
        if (this.state.rewardList && this.state.rewardList.length > 0) {
            myPlan.rewards = this.state.rewardList;
        }
        if (myPlan && myPlan.rewards) {
	    rewardCount = myPlan.rewards.length;
            // reward list
	    rewardViews = myPlan.rewards.map(
                (reward, index) =>
	            (
		        <div
		          className="reward-card"
		          key={"reward"+index}>
                          {reward.image?
		           <img src={reward.image} alt=""/>:""}
		          <div className="content">
			    <h1>{reward.description}</h1>
			    <p>{reward.title? reward.title: reward.name}</p>
		          </div>
		          <Button className="reveal-button" disabled={!reward.enabled || reward.rewarded } onClick={this.handleRewardClaim.bind(this, reward)}>{reward.rewarded?"REWARDED":"CLAIM"}</Button>
		        </div>
	            )
	    );
        }
        return (
            <div>
	      <div className="gallery-list">
	        <div className="title">SPECIAL BENEFIT</div>
                  <div className={"plan-list scroll-container " + (planCount===0?'shink':'expand')}>
	          {planViews.length > 0? planViews: "No available plans"}
                </div>
	        {planViews.length > 0?
	         <Button disabled={!planEnabled || !selectedPlanFinal || planRegistered} className="reveal-button" onClick={this.handleConfirmPlan.bind(this)}>{planRegistered?"REGISTERED":"ACTIVATE"}</Button>:
	         ""}
                  {rewardCount !== 0?
		   <div className="title">REWARD</div>
		   :""}
		  {rewardCount !== 0?
                   <div className={"reward-list scroll-container " + (rewardCount===0?'shink':'expand')}>
	               {rewardViews}
                   </div>
		   :""}
	      </div>
	      {this.state.action !== undefined && this.state.action.type.includes("fnf")?
	       <div className="fnf-plan-alert">
	         FNF PLAN은 90일 후부터 해지할 수 있으며, 해지 시 수수료가 발생합니다.<br/>
	         FNF PLAN can be canceled after 90 days, and there will be a fee for cancellation.
	       </div>:""}
            </div>
        );
    }

    prepareMyPlan(myPlan, key) {
	if (myPlan.name === "") return (<div key={"plan"+key}></div>);
        // reward list
        let rewardViews = (<div></div>);
        if (myPlan.rewards) {
	    rewardViews = myPlan.rewards.map(
                (reward, index) =>
	            (
		        <div
		          className="my-reward-card"
		          key={"reward"+index}>
		          <div className="content">
			    <h1>{reward.title}</h1>
			    <p>{reward.description}</p>
		          </div>
		          <Button className={`reveal-button ${!reward.enabled?"disabled":""}`} disabled={ !reward.enabled || reward.claimed } onClick={this.handlePlanRewardClaim.bind(this, myPlan, reward)}>{reward.claimed?"CLAIMED":"CLAIM"}</Button>
		        </div>
	            )
	    );
        }

        return (
            <div className="plans-card"
		 key={"plan"+key}>
	      <div className="my-plan-card">
                <h1 className="plan-name">{myPlan.name}</h1>
                <div className="my-plan-rewards">
		  {rewardViews}
                </div>

	      </div>
		{(myPlan.deactivate_enabled !== false && myPlan.can_deactivate !== false && !this.state.action.type.includes("upgrade"))?
	       <a href="#none"
		  onClick={this.handleDeactivateClick.bind(this, myPlan)}>DEACTIVATE</a>
               :""}
            </div>
	);
    }

    prepareUpgradePlans(upgradePlans) {
        // upgrade plans
	let upgradeViews = (<div></div>);
        if (upgradePlans) {
            upgradeViews = upgradePlans.map(
                (plan, index) =>
	            (
		        <div
		          className={`upgrade-plan-card ${plan.enabled ? "": "disabled"}`}
		          key={"upgrade" + index}
			  onClick={this.handleUpgradeClick.bind(this, plan)}>
		          <div className="content">
			    <h1>{plan.title}</h1>
			    <p>{plan.description}</p>
		          </div>
                          <i className="arrow right"></i>
		        </div>
	            )
	    );
        }
	let upgradeView = (<div></div>);
	if (upgradePlans && upgradePlans.length > 0) {
	    upgradeView = (
		<div>
		    <h1 className="my-plan-title">UPGRADE AVAILABLE</h1>
		    <div className="upgrade-plans">
			{upgradeViews}
		    </div>
		</div>
	    );
	}
	return upgradeView;
    }


    prepareItemRows(nftList, nftType) {
	let rows = [];
	let colCount = 0;
	let cols = [];
	let index = 0;
	let rowIndex=0;
	let selectedList = nftList;
	for (let box of selectedList) {
	    index++;
	    cols.push(
		(
		    <Col xs={3} key={index}>
		      <a
			className="gallery-item"
			href="#none"
			onClick={this.handleItemClick.bind(this, box)}>
			<img src={box.image_url} alt=""/>
			{box.generation !== undefined && nftType === "shoes"?
			 <div className="generation">
			   G{box.generation}
			 </div>:""}
			<div className="item-id">
			  #{String(box.number).padStart(6, '0')}
			</div>
			{box === this.state.selectedItem?
			 <div className="selected-cover">
			   <img src="/ic_check.png" alt=""/>
			 </div>
			 :<div></div>
			}
		      </a>
		    </Col>
		)
	    );
	    colCount++;
	    if (colCount === 4) {
		rowIndex++;
		rows.push(
		    (
			<Row key={rowIndex}>
			  {cols}
			</Row>
		    )
		);
		cols = [];
		colCount = 0;
	    }
	}
	rowIndex++;
	if (colCount > 0) {
	    rows.push(
	        (
		    <Row key={rowIndex}>{cols}</Row>
	        )
	    );
	}
	return {
	    itemRows: rows,
	    itemCount: index,
	};
    }

    handleSelectPlanClick(plan) {
	if (plan.enabled) {
	    this.setState({ selectedPlan: plan });
	} else {
	    if (!plan.registered) {
		alert("혜택 대상이 아닙니다. You cannot select this plan");
	    }
	}
    }

    handleConfirmPlan() {
	this.props.handleConfirmPlan(this.state.selectedPlan);
    }

    handleRewardClaim(reward) {
	this.props.handleRewardClaim(reward);
    }

    handlePlanRewardClaim(plan, reward) {
	this.props.handlePlanRewardClaim(plan, reward);
    }

    handleUpgradeClick(plan) {
        if (plan.enabled) {
	    this.props.handleUpgradeClick(plan);
        }
    }

    handleDeactivateClick(plan) {
        console.log(plan);
	this.props.handleDeactivateClick(plan);
    }

    render() {
	const {
	    nftList,
	    planList,
	    selectedPlan,
	    nftType,
	    myPlan,
	    myplans,
	    upgradePlans,
	} = this.state;


	// nft list
	let {
	    itemRows, itemCount
	} = this.prepareItemRows(nftList, nftType);

        let rightSideView = undefined;
	let upgradeView = this.prepareUpgradePlans(upgradePlans);
        if (myPlan
	    && (myPlan.plan_key === "" || !myPlan.plan_key)
	    && (!upgradePlans || upgradePlans.length === 0)
	    && (myPlan.name && !myPlan.name.includes("12,000 FIT PLAN"))
	    && (myPlan.name && !myPlan.name.includes("LEGEND PLAN"))
	   ) {
	    rightSideView = this.preparePlanAndRewards(myPlan, planList, selectedPlan);
        } else if (myPlan) {
	    let views = [this.prepareMyPlan(myPlan, 1)];
	    rightSideView = (
		<div className="my-plan-container">
		    {myPlan.name !== ""?
		     <h1 className="my-plan-title">MY PLAN</h1>
		     :""}
		    {views}
		    {upgradeView}
		</div>
	    );
        } else if (myplans) {
	    let views = myplans.map(
		(plan, index) => this.prepareMyPlan(plan, index)
	    );
	    rightSideView = (
		<div className="my-plan-container">
		    <h1 className="my-plan-title">MY PLAN</h1>
		    {views}
		    {upgradeView}
		</div>
	    );
	} else {
            rightSideView = this.preparePlanAndRewards({
                title: "SPECIAL BENEFIT"
            }, planList, selectedPlan);
        }

	return (
	    <Row className="container-gallery">
	      <Col md={6}>
		<div className="title">MY {nftType.toUpperCase()}</div>
		<div className="gallery-list">
                  <div className="nft-list scroll-container">
                    {itemCount > 0?
		     itemRows:
		     ""
		    }
		    {itemCount === 0?
		     <div>No results</div>:
		     ""
		    }
                  </div>
		</div>
	      </Col>
	      <Col md={6}>
		{rightSideView}
	      </Col>
	    </Row>
	);
    }
}

export default AchievementGallery;
