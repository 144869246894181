import React from 'react';

import "./css/AchievementContent.css";

import Container from 'react-bootstrap/Container';

import AchievementGallery from './AchievementGallery.js';
import PlanUpgradeModal from './PlanUpgradeModal.js';
import PlanDeactivateModal from './PlanDeactivateModal.js';
import ApiService from '../api/api.js';
import sleep from '../utils/sleep.js';

class AchievementContent extends React.Component {

    constructor(props) {
	super(props);
	this.state = {
	    action: props.action,
	    nftList: [],
            rewardList: [],
	    planList: undefined,
            myPlan: undefined,
	    myplans: undefined,
            upgradePlans: undefined,
	};
	this.apiService = new ApiService(props.navigate);
	this.confirmModalRef = React.createRef();
	this.upgradeModalRef = React.createRef();
	this.deactivateModalRef = React.createRef();
    }

    componentDidMount() {
	this.checkLogin();
	this.loadAchievement();
    }

    async checkLogin() {
	await sleep(100);
	if (!this.apiService.isLoggedIn()) {
	    this.props.navigate("/");
	    return;
	}
	if (!this.props.action) {
	    this.props.navigate("/select");
	    return;
	}
    }

    async loadAchievement() {
	let response = await this.apiService.getAvailableAchievements(
	    this.state.action.type
	);
        // console.log(response.data);
	if (response.status === 200) {
	    this.setState(response.data);
	    // console.log(response.data.nftList.map((nft) => nft.number))
	}
	if (response.status !== 200
	    || (response.type === "plan" && !(response.data.myPlan || response.data.myplans))) {
	    alert("Active plan does not exist.");
	    this.props.navigate("/select");
	}
    }

    async handleRewardClaim(reward) {
        let key = reward.claim_key;
        if (!key) key = reward.rewardKey;
	let response = await this.apiService.claimReward(
	    this.state.action.type,
            key,
            reward.is_achievement
	);
	if (response.status === 200) {
	    this.loadAchievement();
	} else {
	    alert("ERROR " + response.status);
	}
    }

    async handlePlanRewardClaim(plan, reward) {
	let response = await this.apiService.claimPlanReward(
	    this.state.action.type,
	    plan.plan_key,
	    reward.claim_key,
	    plan.plan_group,
	);
	if (response.status === 200) {
	    this.loadAchievement();
	} else {
	    alert("ERROR " + response.status);
	}
    }

    async onPlanConfirmed(plan) {
        this.loadAchievement();
    }

    async onPlanUpgradeConfirmed(plan) {
        this.loadAchievement();
    }

    async onDeactivateConfirmed(plan) {
	this.loadAchievement();
    }

    handleConfirmPlan(plan) {
	this.confirmModalRef.current.open(plan);
    }

    handleUpgradeClick(plan) {
	this.upgradeModalRef.current.open(plan);
    }

    handleDeactivateClick(plan) {
	this.deactivateModalRef.current.open(plan);
    }

    render() {
	let nftType = "";
	let title = "";
	let desc = "";
	if (this.state.action) {
	    nftType = this.state.action.nftType;
	    title = this.state.action.kr;
	    desc = this.state.action.en;
	}
        return (
	    <div>
		<div className="container-root">
		    <Container fluid="md">
			<div className="container-reveal container-actions">
			    <h1>{title}</h1>
			    <p>{desc}</p>
			    <AchievementGallery
				nftType={nftType}
				action={this.state.action}
				nftList={this.state.nftList}
				planList={this.state.planList?this.state.planList:this.state.newPlans}
				rewardList={this.state.rewardList}
				myPlan={this.state.myPlan}
				myplans={this.state.myplans}
				upgradePlans={this.state.upgradePlans}
				navigate={this.props.navigate}
				handleRewardClaim={this.handleRewardClaim.bind(this)}
				handlePlanRewardClaim={this.handlePlanRewardClaim.bind(this)}
				handleConfirmPlan={this.handleConfirmPlan.bind(this)}
				handleUpgradeClick={this.handleUpgradeClick.bind(this)}
				handleDeactivateClick={this.handleDeactivateClick.bind(this)}/>

			</div>
		    </Container>
		</div>
		<PlanUpgradeModal
		    ref={this.confirmModalRef}
		    action={this.state.action}
		    onPlanConfirmed={this.onPlanConfirmed.bind(this)}
		    nftList={this.state.nftList}/>
		<PlanUpgradeModal
		    ref={this.upgradeModalRef}
		    action={this.state.action}
		    onPlanConfirmed={this.onPlanUpgradeConfirmed.bind(this)}
		    nftList={this.state.nftList}
                    basePlan={this.state.myPlan}/>
		<PlanDeactivateModal
		    ref={this.deactivateModalRef}
		    onDeactivateConfirmed={this.onDeactivateConfirmed.bind(this)}
		    action={this.state.action}/>
	    </div>
	);
    }
}


export default AchievementContent;
