import React from 'react';

import "./css/RevealModal.css";

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import ApiService from '../api/api.js';

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class LoginButton extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    isLoginModalOpen: false,
	    isLoggedIn: false,
	    email: '',
	    code: '',
	};
	this.apiService = new ApiService(this.props.navigate);
    }

    componentDidMount() {
	this.setState({
	    isLoggedIn: this.apiService.isLoggedIn()
	});
    }

    onConnectButtonClick() {
	if (!this.state.isLoginModalOpen) {
	    this.setState({
		isLoginModalOpen: true,
	    });
	}
    }

    closeSelectorModal(name) {
	this.setState({
	    isLoginModalOpen: false,
	});
    }
    
    async login() {
	let response = await this.apiService.verifyEmail(
	    this.state.email,
	    this.state.code,
	);
	if (response.status === 200) {
	    sessionStorage.setItem(
		'email',
		response.data.email
	    );
	    sessionStorage.setItem(
		'x-web-access-token',
		response.data.web_access_token
	    );
	    this.props.onLogin();
	    this.setState({
		isLoginModalOpen: false,
		isLoggedIn: true
	    });	    
	} else {
	    alert("ERROR");
	}	
    }

    async logout() {
	sessionStorage.setItem(
	    'email',
	    ''
	);
	sessionStorage.setItem(
	    'x-web-access-token',
	    ''
	);	    
	this.setState({
	    isLoginModalOpen: false,
	    isLoggedIn: false
	});
	this.props.navigate("/");
    }

    onChangeEmail(e) {
	this.setState({
	    email: e.target.value
	})
    }

    onChangeCode(e) {
	this.setState({
	    code: e.target.value
	})
    }

    async onSendEmail() {
	let response = await this.apiService.getVerification(this.state.email);
	if (response.status === 200) {
	    alert("Verification code is sent to " + this.state.email);
	}
    }

    getLoginContainer() {
        return (
            <div>
		<div className="modal-header">
	            <h3>로그인을 진행해주세요</h3>
	            <p>Please login to SNKRZ</p>
                    <Button className="close-button" onClick={this.closeSelectorModal.bind(this)}>X</Button>
		</div>
		<div className="modal-container">
                    <div className="email-input-container">
			<input type="text" className="form-control form-control-lg" placeholder="Email" name="name" value={this.state.email} onChange={this.onChangeEmail.bind(this)}/>
			<Button className="snkrz-btn-normal send-email-button" onClick={this.onSendEmail.bind(this)}>SEND</Button>
                    </div>
                    <div className="code-input-container">
			<input type="text" className="form-control form-control-lg" placeholder="Verification Code" name="verification_code" value={this.state.code} onChange={this.onChangeCode.bind(this)}/>
			<p className="verification-timer">3:00</p>
                    </div>
                    <Button className="ok-button" onClick={this.login.bind(this)}>Login</Button>
		</div>
            </div>
        );	
    }

    getLogoutContainer() {
	return (
            <div>
              <div className="modal-header">
	        <h3>로그아웃 하시겠습니까?</h3>
	        <p>Would you like to log out?</p>
	      </div>
              <div className="modal-container modal-logout">
		  <Button className="snkrz-btn-normal" onClick={this.closeSelectorModal.bind(this)}>
		      Cancel
		  </Button>
		  <Button className="snkrz-btn-normal dark" onClick={this.logout.bind(this)}>
		      Ok
		  </Button>
              </div>
            </div>
        );	
    }
    
    render() {
	let modalContainer;
	if (this.state.isLoggedIn) {
	    modalContainer = this.getLogoutContainer();
	} else {
	    modalContainer = this.getLoginContainer();
	}
	return (
	    <Button
	      variant="light"
	      onClick={this.onConnectButtonClick.bind(this)}
	      className="btn-login">
	      <span className="ic-login"></span>
		{this.state.isLoggedIn?"LOGOUT":"LOGIN"}
	      <Modal
		isOpen={this.state.isLoginModalOpen}
		onRequestClose={this.state.closeSelectorModal}
		ariaHideApp={false}
		style={modalStyles}>
		<div className="modal-wallets modal-content modal-login">
		    {modalContainer}
		</div>
	      </Modal>
	    </Button>
	);
    }
}

export default LoginButton;

