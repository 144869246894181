import React from 'react';

import "./css/Footer.css";

class Footer extends React.Component {
    
    render() {
	return (
            <div className="footer">
		
            </div>
	);
    }
}


export default Footer;
