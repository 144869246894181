import React from 'react';

import "./css/ItemGallery.css";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

class RevealGallery extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
            nftList: props.nftList,
	    selectedItem: undefined,
	    selectedTab: "box",
        };
    }
    
    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }
    
    handleRevealClick() {
	this.props.handleBoxOpenClick(this.state.selectedItem);
    }

    handleItemClick(box) {
	this.setState({ selectedItem: box })
    }

    handleTabClick(tab) {
	this.setState({
	    selectedTab: tab,
	    selectedItem: undefined
	})	
    }

    prepareItemRows(selectedTab, nftList) {
	let rows = [];
	let colCount = 0;
	let cols = [];
	let index = 0;
	let rowIndex=0;
	let selectedList = nftList.box;
	if (selectedTab === "skin") {
	    selectedList = nftList.item;
	}
	for (let box of selectedList) {
	    index++;
	    cols.push(
		(
		    <Col md={3} key={index}>
			<a
			    className="gallery-item"
			    href="#none"
			    onClick={this.handleItemClick.bind(this, box)}>
			    <img src={box.image_url} alt=""/>
			    <div className="item-id">
				#{String(box.number).padStart(6, '0')}
			    </div>
			    {box === this.state.selectedItem?
			     <div className="selected-cover">
				 <img src="/ic_check.png" alt=""/>
			     </div>
			     :<div></div>
			    }
			</a>
		    </Col>
		)
	    );
	    colCount++;
	    if (colCount === 4) {
		rowIndex++;
		rows.push(
		    (
			<Row key={rowIndex}>
			    {cols}
			</Row>
		    )
		);
		cols = [];
		colCount = 0;
	    }
	}
	rowIndex++;
	if (colCount > 0) {
	    rows.push(
	        (
		    <Row key={rowIndex}>{cols}</Row>
	        )
	    )
	}
	return {
	    itemRows: rows,
	    itemCount: index,
	};
    }
    
    render() {
	const {
	    selectedItem,
	    nftList,
	    selectedTab,
	} = this.state;
	let {
	    itemRows, itemCount
	} = this.prepareItemRows(selectedTab, nftList);
	return (
	    <Row className="container-gallery">
		<Col md={6}>
		    <div className="item-selected" alt="">
			{selectedItem?
			 <img src={selectedItem.image_url} alt=""/>
			 :""
			}
			{!selectedItem && selectedTab === "box"?
			 <h3 className="guide">Select SKIN BOX<br/>(스킨 상자를 선택해주세요)</h3>
			 :""
			}
			{!selectedItem && selectedTab === "skin"?
			 <h3 className="guide">Select SKINS<br/>(스킨을 선택해주세요)</h3>
			 :""
			}			
		    </div>
		    {selectedTab==="box"?
		     <Button disabled={selectedItem === undefined} className="reveal-button" onClick={this.handleRevealClick.bind(this)}>REVEAL</Button>:""}
		    {selectedItem && selectedTab==="skin"?
		     <Row className="item-desc">
			 <Col md={4} className="desc">
			     <span className="title">NUM</span><span className="content">#{String(selectedItem.number).padStart(6, '0')}</span>
			 </Col>
			 <Col md={4} className="desc">
			     <span className="title">RARITY</span><span className="content">{selectedItem.rarity}</span>
			 </Col>
			 <Col md={4} className="desc">
			     <span className="title">MODE</span><span className="content">{selectedItem.mode}</span>
			 </Col>
		     </Row>:""}
		    {!selectedItem && selectedTab==="skin"?
		     <Row className="item-desc">
			 <Col md={4} className="desc">
			     <span className="title">NUM</span><span className="content">-</span>
			 </Col>
			 <Col md={4} className="desc">
			     <span className="title">RARITY</span><span className="content">-</span>
			 </Col>
			 <Col md={4} className="desc">
			     <span className="title">MODE</span><span className="content">-</span>
			 </Col>
		     </Row>:""}
		</Col>
		<Col md={6}>
		    <div className="item-tab">
			<Button className={selectedTab==="box"?"tab-selected":"tab-unselected"} onClick={this.handleTabClick.bind(this, "box")}>SKIN BOX</Button>
			<Button className={selectedTab==="skin"?"tab-selected":"tab-unselected"} onClick={this.handleTabClick.bind(this, "skin")}>SKIN</Button>
		    </div>
		    <div className="gallery-list">
			{itemCount > 0?
			 itemRows:
			 ""
			}
			{itemCount === 0 && selectedTab === "box"?
			 <div>No results<br/> (남은 스킨 박스가 없습니다)</div>:
			 ""
			}
			{itemCount === 0 && selectedTab === "skin"?
			 <div>No results<br/> (리빌된 스킨이 없습니다)</div>:
			 ""
			}			
		    </div>
		</Col>
	    </Row>
	);
    }
}


export default RevealGallery;
